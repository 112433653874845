import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangePassword, ChangeUserPassword } from 'src/app/core/models/change-password.model';
import { RestrictionContainer } from 'src/app/core/models/restriction.model';
import { Observable } from 'rxjs';
import { User, UserInfo } from 'src/app/core/models/auth/user.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { ResendCode } from 'src/app/core/models/resend-code.model';
import { AdvancedPermission, Permission } from 'src/app/core/models/permission.model';
import { BaseModel } from 'src/app/core/models/base.model';

@Injectable({
	providedIn: 'root',
})
export class UserSettingsService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	getUser(userId: number): Observable<User> {
		return this.http.get<User>(`${this.appConfigurationService.api.service}/Security/User/Load?id=${userId}`);
	}

	getUserInfo(userId: number): Observable<UserInfo> {
		return this.http.get<UserInfo>(`${this.appConfigurationService.api.auth}/user/${userId}`);
	}

	getCurrentUser(): Observable<UserInfo> {
		return this.http.get<UserInfo>(`${this.appConfigurationService.api.auth}/user/myprofile`);
	}

	getUserPermissions(id: number): Observable<Permission[]> {
		return this.http.get<Permission[]>(`${this.appConfigurationService.api.auth}/user/${id}/permissions`);
	}

	getUserAdvancedPermissions(userId: number): Observable<AdvancedPermission[]> {
		return this.http.get<AdvancedPermission[]>(
			`${this.appConfigurationService.api.auth}/user/${userId}/advancedPermissions`
		);
	}

	sync() {
		return this.http.post(`${this.appConfigurationService.api.service}/Sync/DailySync`, {});
	}

	getRoles(userId) {
		return this.http.get(`${this.appConfigurationService.api.auth}/User/${userId}/roles`);
	}

	getPermissions(userId, roleId) {
		return this.http.get(`${this.appConfigurationService.api.auth}/User/${userId}/${roleId}/permissions`);
	}

	savePermissions(userId, permissions) {
		return this.http.post(`${this.appConfigurationService.api.auth}/User/${userId}/savePermissions`, permissions);
	}

	saveInfo(userDto): Observable<UserInfo> {
		return this.http.post<UserInfo>(`${this.appConfigurationService.api.auth}/User/Save`, userDto);
	}

	updateRole(userId: number, roleId: number) {
		return this.http.post<UserInfo>(`${this.appConfigurationService.api.auth}/User/updateRole`, {
			userId,
			roleId,
		});
	}

	changePassword(changePassword: ChangePassword): Observable<ChangeUserPassword> {
		return this.http.post<ChangeUserPassword>(
			`${this.appConfigurationService.api.auth}/Auth/change_password`,
			changePassword
		);
	}

	resetPassword(userName: string, platformId: number) {
		return this.http.post(
			`${this.appConfigurationService.api.auth}/Auth/forgot_password?userName=${userName}&platformId=${platformId}`,
			null
		);
	}

	getIPs(userId) {
		return this.http.get(`${this.appConfigurationService.api.auth}/BlackList/Search?userId=${userId}`);
	}

	saveIPs(restrictionContainer: RestrictionContainer) {
		return this.http.post(`${this.appConfigurationService.api.auth}/BlackList/Save`, restrictionContainer);
	}

	resendAuthenticationCode(resendCode: ResendCode): Observable<number> {
		return this.http.post<number>(
			`${this.appConfigurationService.api.service}/Security/User/ResendAuthenticationCode`,
			resendCode
		);
	}

	resendInvitation(resendCode: ResendCode): Observable<number> {
		return this.http.post<number>(
			`${this.appConfigurationService.api.service}/Security/User/ResendInvitation`,
			resendCode
		);
	}

	getAllRoles(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.auth}/Role/LoadAll`);
	}

	getAllActiveUsers(): Observable<any[]> {
		return this.http.get<any[]>(`${this.appConfigurationService.api.auth}/user/loadall-active`);
	}
}
